.page-login {
    background-image: url('../images/login-bg.jpg');
    background-size: 100%;

    .auth-form-light {
        box-shadow: 0px 0px 5px 0px #101010;

    }

    .content-wrapper {
        background-color: transparent;

        h3 {
            font-weight: bold;

            span {
                color: #1f3bb3
            }
        }
    }
}

.pagination {
    justify-content: flex-end;
    li{
        border: 1px solid #ccc;
        cursor: pointer;
        a{
        padding: 10px 15px;
      
        
    }
    }
}
.recpayments{
    background-color: #bffff2b5;
    padding: 10px;
    width: 100%;
    label{
        font-weight: 700;
    }
    p{
        font-weight: 600;
    }
}
input.form-control::placeholder,
select.form-control {
    color: #666;
    opacity: 1;
}

.cursor-pointer {
    cursor: pointer;
}

.filter-btn {
    width: 100%;
    padding: 7px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    i {
        margin-right: 5px;
    }

    &.reset-btn {
        background-color: #c14a3a;
        border-color: #c14a3a;
    }

    &.export {
        background-color: #369136;
        border-color: #369136;
    }
}

.sidebtn-mb{
    display:none;
}

@media screen and (max-width: 991px) {
    .navbar{
        padding: 0px 15px !important;
    }
    .sidebar-offcanvas.active {
        right: auto;
    }
    .navbar .navbar-brand-wrapper{
        height:auto;
        padding:0;
    }
    .sidebtn-mb{
        display:block;
        padding:0 0 20px 0;
        text-align: right;
        .btn{
            padding:10px 20px;
        }
    }

    .navbar .navbar-menu-wrapper .navbar-nav .nav-item .welcome-text.mb-header-text {
        font-size: 14px;
        margin-bottom: 0;
        text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    }

    .navbar .navbar-menu-wrapper .navbar-nav .nav-item .text-end {
            .btn{
                padding:5px 7px;
                margin-left: -20px;
                font-size: 12px;
                margin-top:7px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
    }
    .sidebar-offcanvas{
        top:66px;
        box-shadow: 0 0 5px -1px #000;
    }
    .filter-btn {
        margin-top: 12px;
        margin-bottom: 7px;

    }

    .btn-section {
        justify-content: center;
        margin: auto;
    }

    .navbar .navbar-brand-wrapper .navbar-brand.brand-logo-mini {
        display: inline-block;
        margin-left: 10px;
        margin-bottom: 5px;
    }

    .navbar .navbar-menu-wrapper {
        padding-left: 5px;
        padding-bottom: 15px;
    }
    .page-body-wrapper{
        padding-top:66px;
    }
}

.form-group .multiSelectContainer ul {
    overflow-y: scroll;
    max-height: 120px;
    padding-bottom: 20px;
}
.print-header{
    display: none;
}
.print-btn{
    display: flex;
    justify-content: center;
    align-items: center; 
    text-decoration: none;
    span{ 
        margin-right: 2px;
    }
}
@media print { 
    /* All your print styles go here */
    nav, .navbar, button ,.hidePrint, .Toastify,.menu-icon{ display: none !important; } 
    .print-header{
        display: block;
        padding: 10px 0;
    }
   }
   .mdi-pencil{
    position: relative;
   }
   .mdi-pencil::before {
    content: "\F3EB";
    position: absolute;
    right:  0;
    top: 0;
    font-size: 20px;
    left: 0;
    color:blue;
    
}

.mdi-delete{
    position: relative;
   }
   .mdi-delete::before {
    
    position: absolute;
    right:  0;
    top: 0;
  
    font-size: 20px;
    left: 0;
    color:#d96565;
}



// .public-DraftStyleDefault-ltr {
   
//     border: 1px solid #ccc;
//     min-height: 300px;
//     padding:20px;
// }
.wrapperClassName {

       border: 1px solid #ccc;
    min-height: 300px;
    padding:20px;
}
.image-input {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .image-input input {
    display: none;
  }
  .image-input label {
    display: flex;
    align-items: center;
    color: #FFF;
    background: #1f3bb3;
    padding: 0.3rem 0.6rem;
    font-size: 15px;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .image-input label i {
    font-size: 20px;
    margin-right: 0.3rem;
  }
  .image-input label:hover i {
    animation: shake 0.35s;
  }
  .image-input img {
    max-width: 100px;
    
  }
  .image-input span {
    display: none;
    text-align: center;
    cursor: pointer;
  }
  .image-input .image-button span  {
    display: block;
    
  }
  @keyframes shake {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(10deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  