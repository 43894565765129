.dashboard-card
{
    i{
        font-size:30px;
        color:#fff;
    }
    .card-title.card-title-dash{
        font-size:24px;
    }
   
}

.bg-primary-staff{
    background-color: #50065cc4;
}
.bg-primary-property{
    background-color: #b10b44e8;
}
// 00544c
.bg-primary-area{
    background-color: #00544c;
}
.bg-primary-elec{
    background-color: #a259af;
    --bs-bg-opacity: 1;
}
.bg-primary-water{
    background-color: #6c9b36;
    --bs-bg-opacity: 1;
}
.bg-primary-maint{
    background-color: #e34a7e;
    --bs-bg-opacity: 1;
}
.bg-primary-tranf{
    background-color: #d53f34;
    --bs-bg-opacity: 1;
}
.bg-primary-allot{
    background-color: #003d44;
    --bs-bg-opacity: 1;
}
@media only screen and (max-width: 360px) {
    i{
        font-size:25px !important;
        
    }
    .card-title.card-title-dash{
        font-size: 20px !important;
    }
  }
